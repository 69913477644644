<template>
  <div class="content">
    <div class="main">
      <div class="title">活动规则</div>
      <div class="time">活动时间</div>
      <div class="time_desc">{{activity_time}}</div>
      <div class="time">活动详情</div>
      <div class="time_desc2"><div>① 参与活动需扫描屏幕上的二维码填写姓名和手机号报名;</div>
       <div>② 答题时间开始之后可进行答题，规定时间内，按答对的题最多，答题的准确率最高排名；</div>
        <div>③ 获奖的读者，可领取奖品。</div>
      </div>
    </div>
    <div class="close" @click="closethis"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Guize',
  components: {
  },
  props: ['title', 'userinfo', 'activity_time'],
  computed: {
    ...mapGetters(['agencyGuid', 'userGuid'])
  },
  data () {
    return {
      showOverlay: false,
      username: '',
      phone: ''
    }
  },
  watch: {
  },
  methods: {
    closethis () {
      this.$emit('closeguize')
    }
  }
}
</script>

<style scoped lang="stylus">
@import "~@/assets/stylus/mixin"
.content{
  position absolute
  left 0
  right 0
  top 0
  bottom 0
  z-index 10000
  background rgba(0, 0, 0, .7)
}
.main{
  padding:30px;
  background-image:url('~@/assets/img/activity/guize_bg.png')
  background-repeat:no-repeat;
  background-size:contain;
  width:calc(100% - 147px);
  height:833px;
  margin-left:67px;
  margin-top:270px;
  .title{
    text-align:center;
    margin-top:80px;
    font-size: 44px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #E14D46;
    line-height: 90px;
    background: linear-gradient(110deg, #C760C7 0%, #E56B48 0%, #C53C35 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .time{
    margin-top:10px;
    margin-left:15px;
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    width: 179px;
    height: 56px;
    line-height:56px;
    text-align:center;
    background: linear-gradient(110deg, #C760C7, #E56B48, #C53C35);
    border-radius: 28px;
  }
  .time_desc{
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    margin-top:10px;
    padding:0 50px 10px 20px;
    line-height: 53px;
  }
  .time_desc2{
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    margin-top:10px;
    padding:10px 50px 10px 20px;
    line-height: 53px;
  }
}
.close{
  width 60px
  height 60px
  bg-image('~@/assets/img/activity/activity-close')
  background-size 100% 100%
  background-repeat no-repeat;
  position relative;
  top:-30px;
  margin-left:auto;
  margin-right:auto;
}
</style>
