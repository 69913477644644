<template>
  <div class="content">
    <div class="main">
      <div class="title"></div>
      <van-form @submit="onSubmit">
        <van-field
          v-model="userinfo.c_user_name"
          name="username"
          label="姓名"
          placeholder="姓名"
          :rules="[{ required: true, message: '请填写姓名' }]"
        />
        <van-field
          v-model="userinfo.c_user_phone"
          name="phone"
          label="手机号"
          placeholder="手机号"
          :rules="[{ required: true, message: '请填写手机号' }]"
        />
        <div class="bot-btn">
          <van-button class="btn" round block native-type="submit">提交</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { editinfo } from '@/api/Cuser'
import { Toast } from 'vant'
export default {
  name: 'EditUserinfoNew',
  components: {
  },
  props: ['title'],
  computed: {
    ...mapGetters(['agencyGuid', 'userGuid'])
  },
  data () {
    return {
      showOverlay: false,
      username: '',
      phone: '',
      userinfo: { c_user_phone: '', c_user_name: '' }
    }
  },
  watch: {
  },
  methods: {
    onSubmit (values) {
      this._editinfo()
    },
    _editinfo () {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      editinfo({ c_user_guid: this.userGuid, c_user_phone: this.userinfo.c_user_phone, c_user_name: this.userinfo.c_user_name }).then(res => {
        Toast.success(res.msg)
        if (res.code === 200) {
          this.$emit('getCUserInfoNew')
        }
      })
    }
  }
}
</script>

<style scoped lang="stylus">
@import "~@/assets/stylus/mixin"
.content{
  position:absolute;
  width:100%;
  height:100%;
  background-image:url('~@/assets/img/activity/editinfo_bg.png')
  background-repeat:no-repeat;
  background-size:100% 100%;
}
.main{
  padding:30px;
  background-color:#ffffff;
  width: calc(100% - 160px);
  height: 575px;
  background: #FFFFFF;
  border-radius: 24px 0px 24px 0px;
  margin-left:auto;
  margin-right:auto;
  margin-top:410px;
  box-shadow: 0px 5px 10px #eeeeee;
  position relative
  .title{
    height:36px;
    line-height:100px;
    wdith:100%;
    text-align:center;
    background-image:url('~@/assets/img/activity/edit-info-title.png')
    background-repeat:no-repeat;
    background-size:contain;
    background-position:center;
    margin-bottom:58px;
    margin-top:42px;
  }
  .van-cell{
    line-height:30px;
  }
  .bot-btn{
    position:absolute;
    bottom:70px;
    width:calc(100% - 90px);
    text-align:center;
    padding:0 30px;
    .btn{
      width: 532px;
      height: 88px;
      background: #BE0404;
      box-shadow: 0px 4px 13px 0px rgba(190,4,4,0.47);
      border-radius: 44px;
      color:#ffffff;
      border:none;
    }
  }
}
</style>
