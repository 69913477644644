<template>
  <transition :appear="true" name="slide">
    <div class="answer-home" :style="anho_st">
      <div class="guize" @click="showguize">活动规则</div>
      <!-- <div class="header">
        <div class="back" @click="back">
          <div class="icon"></div>
          <div class="name">返回</div>
        </div>
      </div> -->
      <!-- <router-link tag="div" to="/login" class="answer-notice" v-if="!userReadCard">
        您尚未绑定读者证,未绑定读者证无法参与领奖。点此立即绑定读者证
      </router-link> -->
      <div tag="div" class="btn" v-if="hasjoin==false" :style="btn_st1" @click="editinfo=true">点击报名</div>
      <div tag="div" class="btn" v-if="hasjoin" :style="btn_st1">已报名</div>
      <div tag="div" class="rank" :disabled="loading" :style="btn_st1" @click="toanswer">点击答题</div>
      <!-- <van-loading v-if="loading"/> -->
      <!-- <router-link tag="div" to="/vieanswerrank" :style="btn_st" class="rank">排行榜</router-link>
      <router-view></router-view> -->
      <edit-userinfo-new v-if="editinfo==true" title="报名活动" :userinfo="userinfo" v-on:getCUserInfoNew="_getCUserInfoNew"></edit-userinfo-new>
      <guize v-if="show_guize" :activity_time="activity.activity_time" v-on:closeguize="closeguize"></guize>
    </div>
  </transition>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { getActivitiesDetail } from '@/api/Activity'
import { getCUserInfoNew, cUserActivity, getCUserActivity } from '@/api/Cuser'
import editUserinfoNew from '@/components/activity/EditUserinfoNew'
import Guize from '@/components/activity/Guize.vue'

export default {
  name: 'AnswerHome',
  components: {
    editUserinfoNew, Guize
  },
  computed: {
    ...mapGetters(['userGuid', 'agencyGuid'])
  },
  data () {
    return {
      activity: {},
      anho_st: '',
      btn_st: '',
      btn_st1: '',
      editinfo: false,
      activity_guid: '',
      userinfo: {},
      hasjoin: false,
      show_guize: false,
      loading: false
    }
  },
  created () {
    let query = this.$route.query
    this.activity_guid = query.acguid ? query.acguid : '43a9fdf4-c081-4557-9875-e750126d376b'
    let r = query.r ? query.r : ''
    if (r === 1) {
      location.reload()
    }
    // localStorage.setItem('userGuid', 'c921703e-4675-5fa8-f80e-dccbd69af4c8')
    this._getActivitiesDetail()
    this._getCUserInfoNew(1)
  },
  methods: {
    showguize () {
      this.show_guize = true
    },
    closeguize () {
      this.show_guize = false
    },
    _getCUserActivity () {
      getCUserActivity({ c_user_guid: this.userGuid, activity_guid: this.activity.activity_guid }).then(res => {
        if (res.code === 200) {
          this.hasjoin = res.data
          if (res.data === false) {
            this.editinfo = true
          }
        }
      })
    },
    _cUserActivity () {
      cUserActivity({ c_user_guid: this.userGuid, activity_guid: this.activity.activity_guid }).then(res => {
        this._getCUserActivity()
      })
    },
    _getCUserInfoNew (type = 0) {
      getCUserInfoNew({ c_user_guid: this.userGuid }).then(res => {
        if (res.code === 200) {
          this.userinfo = res.data
          if (!res.data.c_user_phone) {
            this.editinfo = true
          } else {
            if (type === 0) {
              this._cUserActivity()
            }
            this.editinfo = false
          }
        }
      })
    },
    toanswer () {
      this._getActivitiesDetail(1)
    },
    back () {
      let index = this.$route.path.indexOf('/vieanswer')
      let path = this.$route.path.substring(0, index)
      this.$router.push(path)
    },
    _getActivitiesDetail (type = 0) {
      let _this = this
      let loading = this.$toast.loading({
        message: '加载中...',
        forbidClick: true, // 禁止背景点击
        duration: 0, // 展示时间，0为持续展示
        overlay: true // 是否显示背景遮罩层
      })
      getActivitiesDetail({ activity_guid: this.activity_guid, c_user_guid: this.userGuid }).then(res => {
        loading.clear()
        if (res.code === 200) {
          _this.activity = res.data
          _this.SET_ACTIVITY(res.data)
          // localStorage.setItem('activity', JSON.stringify(res.data))
          if (type === 1) {
            if (_this.activity.end_time_int <= Math.floor(new Date().getTime() / 1000)) {
              return this.$toast.fail('答题还没开始')
            }
            if (_this.activity.start_time_int > Math.floor(new Date().getTime() / 1000)) {
              return this.$toast.fail('答题还没开始')
            } else {
              // return this.$router.push('/vieanswerquestion')
              // window.location.href = '/#/vieanswerquestion?acguid=' + this.activity.activity_guid
              this.$router.push('/vieanswerquestion?acguid=' + this.activity.activity_guid)
              return false
            }
          } else {
            _this._getCUserActivity()
          }
          // _this.anho_st = 'background-image:url(' + res.data.activitySetting.activity_home_bg_img + ')'
          // if (res.data.activitySetting.activity_color === 2) {
          //   _this.btn_st = 'background-color:#860509;color:#FED5A5;border: 2px solid #C07141;box-shadow: 1px 2px 16px 0px rgba(93,12,15,0.19);'
          //   _this.btn_st1 = 'background-color: #FED5A5;color:#111;'
          // }
        }
      })
    },
    ...mapMutations({
      SET_ACTIVITY: 'SET_ACTIVITY'
    })
  }
}
</script>

<style scoped lang="stylus">
@import "~@/assets/stylus/mixin"
.slide-enter-active, .slide-leave-active
  transition: all 0.3s

.slide-enter, .slide-leave-to
  transform: translate3d(100%, 0, 0)

.answer-home
  position absolute
  z-index 10
  top 0
  left 0
  right 0
  bottom 0
  background RGBA(83, 0, 3, 1)
  background-image:url('~@/assets/img/activity/home_bg.png')
  background-size 100% 100%
  background-repeat no-repeat

  .header
    display flex

    .back
      display flex
      align-items center
      width 160px
      height 84px

      .icon
        width 16px
        height 28px
        margin-left 43px
        //bg-image('~@/assets/img/reading/back-w')
        bg-image('~@/assets/img/reading/back')
        background-size 100% 100%
        background-repeat no-repeat

      .name
        width 100px
        line-height normal
        font-size 26px
        font-weight 500
        color rgba(51, 51, 51, 1)
        margin-left 10px

  .btn
    position absolute
    left 50%
    bottom 206px
    transform translate(-50%, 0)
    display flex
    align-items center
    justify-content center
    width 670px
    height 94px
    background: #FED5A5;
    border-radius 20px
    font-size 32px
    color: #242121;
  .rank
    position absolute
    left 50%
    bottom 90px
    transform translate(-50%, 0)
    display flex
    align-items center
    justify-content center
    width 670px
    height 94px
    background: #860509;
    box-shadow: 1px 2px 16px 0px rgba(93,12,15,0.19);
    border-radius: 20px;
    font-size 32px
    color: #FED5A5;

  .answer-notice
    position absolute
    left 50%
    bottom 202px
    transform translate(-50%, 0)
    display flex
    justify-content center
    width 670px
    font-size 32px
    line-height 50px
    color rgba(51, 51, 51, 1)
.guize{
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #E90D0D;
  width: 194px;
  height: 57px;
  background: #FFFFFF;
  opacity: 0.9;
  border-radius: 29px;
  position:absolute;
  top:94px;
  right:34px;
  text-align:center;
  line-height:57px;
}
</style>
